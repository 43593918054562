import "@/styles/globals.css"
import Layout from "@/components/Layout/Layout"
import ThemeContextProvider from "@/components/Layout/ThemeContextProvider"
import SuperTokensReact, {SuperTokensWrapper} from "supertokens-auth-react"
import {frontendConfig} from "../config/frontendConfig"
import {useEffect} from "react"
import Script from "next/script"
import type {AppProps} from "next/app"
import ProfileContextProvider from "@/contexts/profile.context"
import AppContextProvider from "@/contexts/app.context"
import OrgContextProvider from "@/contexts/org.context"
import Head from "next/head"
import {Inter} from "next/font/google"
import Session from "supertokens-auth-react/recipe/session"
import ProjectContextProvider from "@/contexts/project.context"
import AgSWRConfig from "@/lib/api/SWRConfig"
import dynamic from "next/dynamic"
import {dynamicComponent} from "@/lib/helpers/dynamic"
import {useSentryIntegrations} from "@/lib/helpers/lazyLoadSentryIntegrations"

const NoMobilePageWrapper = dynamicComponent("NoMobilePageWrapper/NoMobilePageWrapper")
const CustomPosthogProvider = dynamic(() => import("@/lib/helpers/analytics/AgPosthogProvider"))

const inter = Inter({
    subsets: ["latin"],
    variable: "--font-inter",
})

if (typeof window !== "undefined") {
    SuperTokensReact.init(frontendConfig())
}

export default function App({Component, pageProps}: AppProps) {
    useSentryIntegrations()

    useEffect(() => {
        async function doRefresh() {
            if (pageProps.fromSupertokens === "needs-refresh") {
                if (await Session.attemptRefreshingSession()) {
                    location.reload()
                } else {
                    // user has been logged out
                    SuperTokensReact.redirectToAuth()
                }
            }
        }
        doRefresh()
    }, [pageProps.fromSupertokens])
    if (pageProps.fromSupertokens === "needs-refresh") {
        return null
    }

    return (
        <>
            <Head>
                <title>Agenta: The LLMOps platform.</title>
                <link rel="shortcut icon" href="/assets/favicon.ico" />
                <script
                    type="text/javascript"
                    src="https://app.termly.io/embed.min.js/8e05e2f3-b396-45dd-bb76-4dfa5ce28e10?autoBlock=on"
                ></script>
            </Head>
            <div className="container">
                <Script src="https://www.googletagmanager.com/gtag/js?id=G-PV7R8H9KDM" />
                <Script id="google-analytics">
                    {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
 
          gtag('config', 'G-PV7R8H9KDM');
        `}
                </Script>
            </div>

            <main className={`${inter.variable} font-sans`}>
                <AgSWRConfig>
                    <CustomPosthogProvider
                        config={{
                            session_recording: {
                                maskAllInputs: false,
                                maskInputOptions: {
                                    password: true,
                                    email: true,
                                },
                            },
                        }}
                    >
                        <SuperTokensWrapper>
                            <ThemeContextProvider>
                                <ProfileContextProvider>
                                    <OrgContextProvider>
                                        <ProjectContextProvider>
                                            <AppContextProvider>
                                                <Layout>
                                                    <Component {...pageProps} />
                                                    <NoMobilePageWrapper />
                                                </Layout>
                                            </AppContextProvider>
                                        </ProjectContextProvider>
                                    </OrgContextProvider>
                                </ProfileContextProvider>
                            </ThemeContextProvider>
                        </SuperTokensWrapper>
                    </CustomPosthogProvider>
                </AgSWRConfig>
            </main>
        </>
    )
}

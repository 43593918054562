import {useEffect, useRef} from "react"
import * as Sentry from "@sentry/nextjs"

export const lazyLoadSentryIntegrations = async () => {
    import("@sentry/nextjs").then((lazyLoadedSentry) => {
        Sentry.addIntegration(
            lazyLoadedSentry.replayIntegration({
                // Additional Replay configuration goes in here, for example:
                maskAllText: true,
                blockAllMedia: true,
            }),
        )
    })
}

export const useSentryIntegrations = () => {
    const isLoading = useRef(false)

    useEffect(() => {
        if (isLoading.current) return
        isLoading.current = true
        lazyLoadSentryIntegrations()
    }, [])
}
